body{
  -webkit-appearance: none
}

/* body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media only screen and (max-width: 600px) {
  .pageContent {
    width: 100%;
  }
  .responsiveSize {
    width: 100%;
  }
}

a {
  text-decoration: none;
  color: black;
}

a :hover {
  color: black;
}

@media only screen and (min-width: 750px) {
  .pageContent {
    width: 90%;
    margin: auto;
  }
  .loginPageContent {
    width: 40%;
  }
  .responsiveSize {
    width: 75%;
  }
  .fifty {
    width: 70%;
  }
  .sixty {
    width: 80%;
    margin: auto;
  }
  .colSpacing {
    width: 47%;
    margin-right: 3%;
  }
  .menuSlider {
    width: 48% !important;
  }
  .topMenu {
    margin-left: 1% !important;
  }
}

@media only screen and (min-width: 1401px) {
  .pageContent {
    width: 60%;
    margin: auto;
  }
  .responsiveSize {
    width: 40%;
  }
  .loginPageContent {
    width: 40%;
  }
  .fifty {
    width: 50%;
  }
  .sixty {
    width: 60%;
    margin: auto;
  }
  .colSpacing {
    width: 40%;
    margin-right: 10%;
  }
  .menuSlider {
    width: 20% !important;
  }
  .topMenu {
    margin-left: 20% !important;
  }
}

.fifty {
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.inputLabelSmall {
  width: 100%;
}

.pageContent {
  padding-top: 50px;
  margin: auto;
}

.responsiveSize {
  margin: auto;
}

.reactIcon {
  font-size: 25px;
  margin-right: 25px;
  cursor: pointer;
  margin-top: 10px;
}

.pointer {
  cursor: pointer;
}

.loginPageContent {
  margin-top: 25px;
  margin: auto;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.inputGroupTextMinWidth {
  min-width: 75x;
}

.scrollMobile {
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.title-subtitle-0 {
  text-align: center;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: xx-large;
  font-weight: 600;
}

.title-subtitle-1 {
  text-align: center;
  margin-bottom: 25px;
  margin-top: 10px;
  font-size: x-large;
  font-weight: 600;
}

.title-subtitle-2 {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 10px;
  font-size: large;
  font-weight: 600;
}

.outerContainer {
  position: relative;
  width: 100%;
}

.innerContainer-left {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 5px;
}

.innerContainer-right {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 5px;
}

.innerContainer-left-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-top: 5px;
}

.innerContainer-right-bottom {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-top: 5px;
}
.sliderContent {
  position: relative;
  height: 250px;
}

.sliderWrapper {
  margin-top: 55px;
  left: 40%;
  height: 250px;
} */
