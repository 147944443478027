.itemgroupitemwrapper-container {
    display: flex;
    width: 100%;
    height: 100px;
   
  }
  
  .itemoverviewcard-image-container {
    width: 30%;
    position: relative;
    margin-bottom: 10px;
  }
  .itemoverviewcard-content-container {
    width: 70%;
    position: relative;
    margin-bottom: 10px;
  }
  
  .itemoverviewcard-content-itemstoreimage-container {
    margin-right: 3px;
  }
  
  .itemoverviewcard-content-price-container {
    position: absolute;
    background-color: transparent;
    right: 10px;
    bottom: 5px;
  }
  
  .itemoverviewcard-content-price {
    font-size: 1.2em;
  }
  
  .itemoverviewcard-content-description-container {
    position: relative;
    background-color: transparent;
    text-align: center;
  }
  
  .itemoverviewcard-content-description {
    font-size: 1em;
    height: 55px;
  }
  
  .itemoverviewcard-promotion-container {
    bottom: 5px;
    position: absolute;
  }
  .itemoverviewcard-promotion-text {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    font-weight: bold;
    color: orange;
    text-transform: uppercase;
    font-size: 0.75em;
  }
  
  .itemoverviewcard-image-itemstoreimage-container {
    position: absolute;
    background-color: transparent;
    left: 0;
    bottom: 0;
  }
  
  .itemoverviewcard-sourcecount {
    position: absolute;
    display: block;
    top: 0px;
    right: 5px;
    color: white;
    background-color: #00ade6;
    font-weight: bolder;
    font-size: 0.75em;
    width: 20px;
    text-align: center;
    border-radius: 20px;
  }
  