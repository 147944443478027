.itemoverviewfilter-container {
  display: flex;
}

.itemoverviewfilter-formcontrol {
  margin-right: 25px !important;
}

.itemoverviewfilter-inputadornment-close {
  margin-top: 20px;
  cursor: pointer;
}
