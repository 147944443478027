.itemsourcecard-container {
  margin-bottom: 15px;
  border-bottom-style: solid;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
}

.itemsourcecard-itemstoreimage-container {
  position: absolute;
  top: 85px;
  left: -5px;
}

.itemsourcecard-content-container {
  display: flex;
  justify-content: space-between;
}

.itemsourcecard-content-promo {
  font-weight: bold;
  color: orange;
  margin-left: 50px;
}

.itemsourcecard-content-priceinfo-container {
  text-align: right;
}

.itemsourcecard-content-priceinfo-price {
  font-size: 1.25em;
}

.itemsourcecard-content-priceinfo-factor {
  font-size: 0.65em;
  text-align: right;
}

.itemsourcecard-content-priceinfo-lastpricedate {
  font-size: 0.65em;
  text-align: right;
}

.itemsourcecard-content-promotion-container {
  font-size: 1em;
  color: black;
  margin-top: -25px;
  min-height: 30px;
}

.itemsourcecard-content-promotion-container-vantot {
  font-size: 0.65em;
}

.itemsourcecard-action-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.itemsourcecard-action-history-container {
  margin-top: -5px;
}

.itemsourcecard-action-history {
  color: black !important;
  font-size: 0.75em;
}

.itemsourcecard-action-delete-container {
  text-align: right;
}

.itemsourcecard-action-delete {
  font-size: 0.75em;
}
