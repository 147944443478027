.itemoverviewcard-container {
  display: flex;
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  border-bottom-style: solid;
  border-bottom-color: lightgray;
  border-bottom-width: 1px;
}

.shadow{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.shadow-light{
  box-shadow: 0 1px 1px rgb(0 0 0 / 0.1);
}

.itemoverviewcard-image-container {
  width: 30%;
  position: relative;
  margin-bottom: 10px;
}
.itemoverviewcard-content-container {
  width: 70%;
  position: relative;
  margin-bottom: 10px;
}

.itemoverviewcard-content-itemstoreimage-container {
  margin-right: 3px;
}

.itemoverviewcard-content-price-container {
  position: absolute;
  background-color: transparent;
  right: 10px;
  bottom: 5px;
}

.itemoverviewcard-content-price {
  font-size: 1.2em;
}

.itemoverviewcard-content-description-container {
  position: relative;
  background-color: transparent;
  text-align: center;
}

.itemoverviewcard-content-description {
  font-size: 1em;
  height: 55px;
}

.itemoverviewcard-promotion-container {
  bottom: 5px;
  position: absolute;
}
.itemoverviewcard-promotion-text {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  font-weight: bold;
  color: orange;
  text-transform: uppercase;
  font-size: 0.75em;
}

.itemoverviewcard-image-itemstoreimage-container {
  position: absolute;
  background-color: transparent;
  left: 0;
  bottom: 0;
}

.itemoverviewcard-sourcecount {
  position: absolute;
  display: block;
  top: 0px;
  right: 5px;
  color: white;
  background-color: #00ade6;
  font-weight: bolder;
  font-size: 0.75em;
  text-align: center;
  border-radius: 20px;
}

.itemoverviewcard-tweedehandssourcecount {
  position: absolute;
  display: block;
  bottom: 10px;
  right: 5px;
  color: white;
  background-color: orange;
  font-weight: bolder;
  font-size: 0.75em;
  width: 20px;
  text-align: center;
  border-radius: 20px;
}
