.logoutHeader{
    background-color: whitesmoke;
    top:0px;
    width: 100%;
    height: 65px;
    position: fixed;
    z-index: 100;
}

.logoutChildren{
    margin:30px;
    margin-top:75px
}

.one-edge-shadow {
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}

.flexContainer{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.flexItemBorder{
 flex-grow: 1;
}

.flexItemMid {
    flex-grow: 3;
}

.flexAlignRight{
    display: contents;
}

.marginLeft25{
    margin-left: 25px;
}

.marginRight25{
    margin-right: 25px;
}

.logoutUserName{
    margin-top:15px;
    margin-right:25px
}

.logo{
    width: 50px;
    margin-top:15px;
}

